import Link from "next/link";
import { styled } from "styled-components";

export const TopicCTA = styled(Link)<{
  $buttonType: "primary" | "secondary";
  $backgroundColor?: "Black" | "White" | "Grey" | "Gradient";
  $type?:
    | "buttonLink"
    | "textLink"
    | "hoverBackgroundLink"
    | "buttonLinkWithContent";
}>`
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  font-weight: 300;
  letter-spacing: 0.9px;
  &:active {
    transform: scale(0.98);
  }
  ${({ $type, $buttonType, $backgroundColor }) =>
    $type === "buttonLink" || $type === "buttonLinkWithContent"
      ? `
		padding: 10px 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		border: 1px solid;
		width: fit-content;
			${
        $backgroundColor === "Black" || $backgroundColor === "Gradient"
          ? "#fff"
          : "#000"
      };

		${
      $buttonType === "primary"
        ? `
                background-color: ${
                  $backgroundColor === "Black" ||
                  $backgroundColor === "Gradient"
                    ? "#fff"
                    : "#000"
                };
				color: ${
          $backgroundColor === "Black" || $backgroundColor === "Gradient"
            ? "#000"
            : "#fff"
        };
				&:hover {
					background-color: transparent;
					color: ${
            $backgroundColor === "Black" || $backgroundColor === "Gradient"
              ? "#fff"
              : "#000"
          };
				}
            `
        : `
                &:hover {
					background-color: ${
            $backgroundColor === "Black" || $backgroundColor === "Gradient"
              ? "#fff"
              : "#000"
          };
					color: ${
            $backgroundColor === "Black" || $backgroundColor === "Gradient"
              ? "#000"
              : "#fff"
          };
				}
            `
    }	
		`
      : $type === "hoverBackgroundLink"
        ? `
			display: flex;
			align-items: unset;
			gap: 5px;
			line-height: 18px;
			width: fit-content;
			padding: 10px;
			color: ${
        $backgroundColor === "Black" || $backgroundColor === "Gradient"
          ? "#fff"
          : "#000"
      };
			svg {
				flex-shrink: 0;
			}
			span {
				width: 90%;
			}

			&:hover {
				background-color: ${
          $backgroundColor === "Black" || $backgroundColor === "Gradient"
            ? "#fff"
            : "#000"
        };
				color: ${
          $backgroundColor === "Black" || $backgroundColor === "Gradient"
            ? "#000"
            : "#fff"
        };
				border-radius: 2px;
			}
		`
        : `
			color: ${
        $backgroundColor === "Black" || $backgroundColor === "Gradient"
          ? "#fff"
          : "#000"
      };
			display: flex;
			width: fit-content;
			gap: 5px;
			text-decoration: underline;
			text-underline-offset: 2px;
			svg {
				flex-shrink: 0;
				position: relative;
				top: 5px;
			}
			&:hover {
					color: #4EA9D1;
				}
		`}

  @media only screen and (max-width: 1280px) {
    font-size: 16px;
    ${({ $type }) =>
      $type === "buttonLink"
        ? `
		padding: 8px 16px;
		`
        : $type === "hoverBackgroundLink"
          ? `
				padding: 8px;`
          : `
				svg {
				    top: 4px;
			    }
				`}
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    ${({ $type }) =>
      $type === "buttonLink"
        ? `
		padding: 6px 12px;
		`
        : $type === "hoverBackgroundLink"
          ? `
				gap: 3px;
				padding: 6px;`
          : `gap: 3px;`}
  }
`;
