import React from "react";
import * as Styles from "./styles";
import CTA, { ICTA } from "../../Topics/CTA";

interface IPrefooterProps {
  internalName: string;
  title?: string;
  backgroundColor?: "Black" | "Gradient";
  paddingTop?: "Small" | "Medium";
  paddingBottom?: "Small" | "Medium";
  cta?: ICTA[];
  variant?: "Two-Column" | "Default";
  lang: string;
}

interface IPrefooterProps {
  internalName: string;
  title?: string;
  backgroundColor?: "Black" | "Gradient";
  paddingTop?: "Small" | "Medium";
  paddingBottom?: "Small" | "Medium";
  cta?: ICTA[];
  variant?: "Two-Column" | "Default";
  lang: string;
}

const PreFooterModule: React.FC<IPrefooterProps> = ({
  title,
  backgroundColor = "Black",
  paddingBottom = "Small",
  paddingTop = "Small",
  cta = [],
  lang,
  variant = "Default",
}) => {
  return (
    <Styles.ModulePreFooter
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $backgroundColor={backgroundColor}
      $variant={variant}
    >
      {title && <h2>{title}</h2>}
      {cta &&
        cta.map((c, i) =>
          variant === "Default" || !variant ? (
            <CTA
              cta={c}
              index={i}
              ctaLength={cta.length}
              lang={lang}
              backgroundColor={backgroundColor}
              key={i}
              type="buttonLink"
            />
          ) : null
        )}
      {cta && variant === "Two-Column" && (
        <div className="cta-row">
          {cta.map((c, i) => (
            <div className="cta-container" key={i}>
              {c.fields.content && <p>{c.fields.content}</p>}
              <CTA
                cta={c}
                index={i}
                lang={lang}
                backgroundColor={backgroundColor}
                type="buttonLinkWithContent"
                ctaLength={cta.length}
              />
            </div>
          ))}
        </div>
      )}
    </Styles.ModulePreFooter>
  );
};

export default PreFooterModule;
