const DEFAULT_DESKTOP_PADDING = "0px";
const SMALL_DESKTOP_PADDING = "50px";
const MEDIUM_DESKTOP_PADDING = "100px";
const DEFAULT_TABLET_PADDING = "0px";
const SMALL_TABLET_PADDING = "40px";
const MEDIUM_TABLET_PADDING = "80px";
const DEFAULT_MOBILE_PADDING = "0px";
const SMALL_MOBILE_PADDING = "10px";
const MEDIUM_MOBILE_PADDING = "20px";

const paddingSizes = {
  Small: {
    Mobile: SMALL_MOBILE_PADDING,
    Tablet: SMALL_TABLET_PADDING,
    Desktop: SMALL_DESKTOP_PADDING,
  },
  Medium: {
    Mobile: MEDIUM_MOBILE_PADDING,
    Tablet: MEDIUM_TABLET_PADDING,
    Desktop: MEDIUM_DESKTOP_PADDING,
  },
  Default: {
    Mobile: DEFAULT_MOBILE_PADDING,
    Tablet: DEFAULT_TABLET_PADDING,
    Desktop: DEFAULT_DESKTOP_PADDING,
  },
};

export const gradientMap = [
  "#F0CF05, #f39f19",
  "#F39F19, #F76531",
  "#F76531, #AA6C7E",
  "#AA6C7E, #4EA9D1",
];

export const FULL_GRADIENT = "#F0CF05, #f76531, #4ea9d1";

export const definePadding = (
  paddingType: "Small" | "Medium" | "Default",
  size: "Desktop" | "Tablet" | "Mobile"
) => {
  return paddingSizes[paddingType][size];
};

export const headerFontStyles = (
  screenSize?: "Tablet" | "Mobile" | "Desktop"
) => {
  switch (screenSize) {
    case "Tablet":
      return `
				font-size: 30px;
				line-height: 25px;
				letter-spacing: 2.55px;
			`;
    case "Mobile":
      return `
				font-size: 22px;
				line-height: 20px;
				letter-spacing: 2.35px;
			`;
    default:
      return `
				font-family: "Agrandir";
				font-size: 55px;
				line-height: 65px;
				letter-spacing: 2.75px;
				text-transform: uppercase;
			`;
  }
};

export const smallHeaderFontStyles = (
  screenSize?: "Tablet" | "Mobile" | "Desktop"
) => {
  switch (screenSize) {
    case "Tablet":
      return `
				font-size: 20px;
				line-height: 26px;
				letter-spacing: 1px;
			`;
    case "Mobile":
      return `
				font-size: 16px;
				line-height: 22px;
				letter-spacing: 0.8px;
			`;
    default:
      return `
				font-size: 24px;
				line-height: 30px;
				letter-spacing: 1.2px;
			`;
  }
};

export const textContentFontStyles = (
  screenSize?: "Tablet" | "Mobile" | "Desktop"
) => {
  switch (screenSize) {
    case "Tablet":
      return `
				font-size: 16px;
				line-height: 28px;
				letter-spacing: 0.8px;
		        gap: 15px;
        
		        ul, ol {
		        	margin-left: 15px;
		        }
			`;
    case "Mobile":
      return `
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 0.7px;
				gap: 10px;
        
		        ul, ol {
		        	margin-left: 10px;
		        }
			`;
    default:
      return `
				font-size: 18px;
			    font-weight: 400;
			    line-height: 30px;
			    letter-spacing: 0.9px;
		        gap: 20px;

				ul, ol {
					margin-left: 20px;

					li:last-of-type {
						margin-bottom: 0;
					}
				}
        
		        ul {
		        	list-style: disc;
		        }


				ol {
		        	list-style: auto;
		        }

		        a {
		        	text-decoration: underline;
		        	text-underline-offset: 2px;
		        	transition: all 0.2s ease-in-out;
		        	&:hover {
		        		color: #4ea9d1;
		        	}
		        }
			`;
  }
};

interface IDefaultStylesProps {
  gap?: string;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  paddingTop?: "Medium" | "Small" | "Default";
  paddingBottom?: "Medium" | "Small" | "Default";
  backgroundColor?: string;
  screenSize?: "Desktop" | "Tablet" | "Mobile";
}

export const defaultStyles = ({
  direction,
  gap,
  paddingTop,
  paddingBottom,
  backgroundColor,
  screenSize,
}: IDefaultStylesProps) => {
  switch (screenSize) {
    case "Tablet":
      return `
		width: 100%;
		padding: 0 30px;
		gap: ${gap};
		padding-top: ${definePadding(paddingTop || "Default", "Tablet")};
		padding-bottom: ${definePadding(paddingBottom || "Default", "Tablet")};`;
    case "Mobile":
      return `
			${gap && `gap: ${gap};`};
		${direction && `flex-direction: ${direction};`};
		padding-top: ${definePadding(paddingTop || "Default", "Mobile")};
		 padding-bottom: ${definePadding(paddingBottom || "Default", "Mobile")};`;
    default:
      return `display: flex;
		 flex-direction: ${direction || "row"};
		 background: ${
       backgroundColor === "Gradient"
         ? "linear-gradient(to left,#4ea9d1,#aa6c7e,#f76531,#efd900)"
         : backgroundColor === "Grey"
           ? "#ECECF2"
           : backgroundColor
             ? backgroundColor
             : "transparent"
     };
		 padding: 0 calc((100vw - 1280px) / 2);
		 color: ${
       backgroundColor === "Black" || backgroundColor === "Gradient"
         ? "#fff"
         : "#000"
     };
		 gap: ${gap};
		 padding-top: ${definePadding(paddingTop || "Default", "Desktop")};
		 padding-bottom: ${definePadding(paddingBottom || "Default", "Desktop")};`;
  }
};
