import Link from "next/link";
import React from "react";
import * as Styles from "./styles";
import { TfiArrowRight } from "react-icons/tfi";

export interface ICTA {
  fields: {
    internalName: string;
    title: string;
    content?: string;
    link: {
      fields: {
        internalName: string;
        url: string;
      };
    };
  };
}

interface ICTAPropsType {
  lang: string;
  index: number;
  ctaLength: number;
  cta: ICTA;
  backgroundColor?: "Black" | "White" | "Grey" | "Gradient";
  type?:
    | "buttonLink"
    | "textLink"
    | "hoverBackgroundLink"
    | "buttonLinkWithContent";
}

const CTA = (props: ICTAPropsType) => {
  const { cta, ctaLength, index, lang, backgroundColor, type } = props;
  return (
    <Styles.TopicCTA
      href={
        cta.fields.link.fields.url.includes("http")
          ? cta.fields.link.fields.url
          : `/${lang}/${cta.fields.link.fields.url}`
      }
      target={cta.fields.link.fields.url.includes("http") ? "_blank" : "_self"}
      $buttonType={
        index % 2 == 0 && ctaLength > 1 && type !== "buttonLinkWithContent"
          ? "primary"
          : "secondary"
      }
      $backgroundColor={backgroundColor}
      $type={type}
    >
      {type === "textLink" || type === "hoverBackgroundLink" ? (
        <TfiArrowRight />
      ) : (
        ""
      )}
      <span>{cta.fields.title}</span>
    </Styles.TopicCTA>
  );
};

export default CTA;
