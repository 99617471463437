import {
  FULL_GRADIENT,
  defaultStyles,
  textContentFontStyles,
} from "@/src/types/StyledComponentVariables";
import { styled } from "styled-components";

export const ModulePreFooter = styled("section")<{
  $backgroundColor?: "Black" | "Gradient";
  $paddingTop?: "Small" | "Medium";
  $paddingBottom?: "Small" | "Medium";
  $variant?: "Two-Column" | "Default";
}>`
  ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
    defaultStyles({
      direction: "column",
      gap: "30px",
      paddingTop: $paddingTop,
      paddingBottom: $paddingBottom,
      backgroundColor: $backgroundColor,
    })};
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(to right, ${FULL_GRADIENT});
  border-image-slice: 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;

  h2 {
    text-align: center;
    font-family: "Agrandir";
    font-size: 42px;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 2.25px;
    text-transform: uppercase;
  }

  .cta-row {
    display: flex;
    flex-direction: row;
    gap: 40px;

    .cta-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: calc((1280px - 40px) / 2);

      p {
        ${textContentFontStyles()}
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "20px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        backgroundColor: $backgroundColor,
        screenSize: "Tablet",
      })};
    h2 {
      font-size: 35px;
      line-height: 35px;
      letter-spacing: 2px;
    }

    .cta-row {
      gap: 30px;

      .cta-container {
        gap: 20px;
        width: calc((100% - 30px) / 2);

        p {
          ${textContentFontStyles("Tablet")}
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${({ $backgroundColor, $paddingTop, $paddingBottom }) =>
      defaultStyles({
        gap: "10px",
        paddingTop: $paddingTop,
        paddingBottom: $paddingBottom,
        backgroundColor: $backgroundColor,
        screenSize: "Mobile",
      })};
    h2 {
      font-size: 25px;
      line-height: 25px;
      letter-spacing: 1.8px;
    }

    .cta-row {
      flex-direction: column;
      gap: 20px;

      .cta-container {
        gap: 10px;
        width: 100%;

        p {
          ${textContentFontStyles("Mobile")}
        }
      }
    }
  }
`;
