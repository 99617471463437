import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const HeroWrapper = styled("section")<{
  $layout?: string;
  $backgroundColor: string;
  $textColor?: string;
  $gradientOverlay?: string;
  $gradientColor?: any;
  $highlightColor?: string;
  $backgroundPosition?: string;
}>`
  font-family: var(--primary-font-family), sans-serif;
  height: ${({ $layout }) => $layout || "77vh"};
  min-height: ${({ $layout }) => $layout || "77vh"};
  position: relative;
  padding: 8rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#000"};
  color: ${({ $textColor }) => $textColor || "#fff"};
  z-index: 0;
  &:before {
    content: '';
    display: ${({ $gradientOverlay }) =>
      $gradientOverlay ? "block" : "none"};  
    background: ${({ $gradientColor }) =>
      $gradientColor
        ? `linear-gradient(90deg, rgba(${$gradientColor},0.8047722342733189) 0%, rgba(${$gradientColor},0.5010845986984815) 65%, rgba(${$gradientColor},0) 100%);`
        : `linear-gradient(90deg, rgba(0,0,0,0.8047722342733189) 0%, rgba(0,0,0,0.5010845986984815) 65%, rgba(0,0,0,0) 100%)`};
    height: 100%;
    width: 100%;
    position: absolute;

    top: 0;
    left: 0;
  }
  button:focus {
  outline: none;
  }

  #centered{
    display: flex:
    flex-direction: 'column'
    width: 100% !important;
    flex: 1 !important;
    max-width: 100% !important;
    text-align: center;
    h1, h2{
       padding: 10px 100px;
       text-align: center;
    }
    h1{
      margin-top: 40px;
    }
  }


  #headline-wrap {
    transition: transform 0.4s;
    box-sizing: border-box !important;
    position: relative !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1280px !important;
    @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    span{
      display: block;
    }
    &.offscreen {
      transform: translateX(-200%);
    }
    img {
      max-width: 600px;
      max-height: 75px;
      margin-bottom: 40px;
      height: 75px !important;
      width: auto !important;
      object-fit: contain !important;
    }
  }
  #hero-cta, #secondary-hero-cta {
    &.button-outline, &.button-solid {
      display: inline-block;
      margin-right: 20px;
      a {
        p {
          font-size: 18px;
          padding: 20px 30px;
          &:after {
            display: none;
          }
        }
      }
    }
    &.button-outline {
      a {
        border: ${({ $highlightColor }) =>
          `2px solid ${$highlightColor}` || "2px solid black"};
        color: ${({ $highlightColor }) => $highlightColor || "#fff"};
        background: transparent;
      }
      &:hover {
        a {
          box-shadow: 2px 2px 1px rgba(0, 0, 0, 1);
        }
      }
    }
    &.button-solid {
      a {
        background-color: ${({ $backgroundColor, $highlightColor }) =>
          $highlightColor || $backgroundColor || "#000"};
        border: ${({ $backgroundColor, $highlightColor }) =>
          $highlightColor
            ? `2px solid ${$highlightColor}`
            : `2px solid ${$backgroundColor}` || "#000"};
        p {
          color: ${({ $highlightColor }) =>
            $highlightColor !== "#00EAE4" ? "#fff" : "#000"};  
        }
      }
      &:hover {
        a {
          background-color: black;
          border: 2px solid black;};
          color: white;
          p {
            color: white;
          }
        }
      }
    }
  }

  #hero-cta > a > p{
      color:#fff;
  }
  #secondary-hero-cta > a > p{
      color:#fff;
  }
  #headline-player-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -20;

    #screen{
      background:rgba(0,0,0,.2);
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index: 20;
      display:block;
      &.hidden{
        display:none;
        @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
          background:rgba(0,0,0,.2);
          display:block;
          z-index:-3;
        }
      }
    }

    #reset-loop{
      background: transparent;
      position: absolute;
      right:0px;
      z-index: 2000;
      border: transparent;
      padding: 20px;
      display:none;
      cursor: pointer;
      &.visible{
        display:inline-block;
      }
    }
    #toggle-mute{
      background: rgba(0,0,0, .4);
      border-radius: 50%;
      position: absolute;
      right:0px;
      bottom: 40px;
      z-index: 2000;
      border: transparent;
      padding: 20px;
      display:inline-block;
      cursor: pointer;
      span {
        width: 30px;
        height: 30px;
        top: 4px;
        left: 4px;
      }
      &.visible{
        display:inline-block;
      }
      
      &.muted {
        &:before {
          opacity: 0.5;
        }
        &:after {
          width: 50%;
          height: 50%;
        }
        &:hover {
          &:before {
            opacity: 0;
          }
          &:after {
            height: 0;
            width: 0;
          }
        }
      }
      &.un-muted {
        &:hover {
          &:after {
            width: 50%;
            height: 50%;
          }
        }
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        z-index: 2002;
        border-radius: inherit;
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: black;
        opacity: 0;
        transition: opacity 300ms ease;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 26px;
        left: 25px;
        z-index: 2001;
        background-size: cover;
        background-image: url('data:image/svg+xml;utf8,<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L17 17" stroke="%23fff" stroke-width="2" stroke-linecap="round"/><path d="M1 17L17 1" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        transition: all 300ms ease;
      }
    }
    .brightcove-react-player-loader {
      width: 100%;
      height: 100%;
      opacity:0;
      transition: opacity .5s;
      &.active{
        opacity:1;
      }
      .vjs-loading-spinner {
        display: none;
      }
      .vjs-big-play-button {
        display: none;
      }
      .video-js {
        width: 100%;
        height: 100%;
        background: transparent;
        video {
          object-fit: cover;
        }
        .vjs-poster {
          background-size: cover;
        }
      }
    }
  }
 .background-image {
    position: absolute !important;
    z-index: -21;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index:-30;
    object-fit: cover;
    object-position: ${({ $backgroundPosition }) =>
      $backgroundPosition || "center center"}!important;
  }

  h1 {
    font-size: 3rem;
    line-height: 0.9;
    font-weight: 900;
    margin-bottom:20px;
    @media only screen and (max-width: 1279px) {
      font-size: 2rem;
    }
    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  p {
    line-height: 1.4;
    font-weight: 600;
    font-size: 18px;
  }

  .headline-multiline {
    h1 {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 0.9;
      font-weight: 700;
      margin-bottom:20px;
    }
    h2 {
      font-size: 3rem;
      line-height: 0.9;
      font-weight: 900;
      margin-bottom:20px;
    }
  }
  #secondary-hero-cta a {
    margin: 0;
  }
  #home-hero video{
    opacity:85%; 
    min-width: 100%; 
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute !important;
    top: 50% !important;
    right: 0px !important;
    transform: translateY(-50%) !important; left: auto !important;
    
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) { 
       img {
        object-position: 100% center !important;
      }
      #home-hero video { 
        top: 50% !important;
        right: auto !important;
        left: auto !important;
      } 
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    #headline-player-wrap {
      .brightcove-react-player-loader {
        .video-js.vjs-playing {
          video {
            object-fit: contain;
          }
          .vjs-poster {
            background-size: cover;
          }
        }
      }
    }
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    min-height: 700px;
    #centered{
      h1, h2{
        padding: 10px 10px;
      }
    }
    #headline-wrap {
      img{
        max-width: 100%;
        max-height: 50px;
        margin-bottom: 20px;
          height: 50px !important;
          width: auto !important;
      }
    }
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
  &#trust-and-security-hero-block {
    min-height: 180px;
    height: 40vh;
  }
`;
export const Teaser = styled("div")`
  background: #08088c;
  color: #fff;
  padding: 10px 0;
  .teaser-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 66vw;
    margin: 0 auto;
    white-space: nowrap;
    .teaser-text-wrapper {
      padding-right: 20px;
      strong {
      }
      p {
        &:after {
          margin-top: 3px;
        }
        a {
          color: #00eae4;
        }
      }
    }

    #teaser-cta {
      a {
        p {
          font-size: 20px;
          margin-top: 10px;
          margin-bottom: 5px;
          color: #00eae4;
        }
      }
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    padding: 20px 0;
    .teaser-container {
      display: block;
      max-width: 80vw;
      white-space: normal;
      #teaser-cta {
        a {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
